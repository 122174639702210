.loading-screen {
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		background-color: white;
		padding: 40px 20px;
		border-radius: 10px;
		box-shadow: 0px 0px 4px #0000001c;
		max-width: 80%;

		img {
			width: 300px;
			max-width: 80%;
			height: auto;
		}
	}

	.terminal {
		margin-top: 20px;
		width: 80%;
		max-width: 600px;
		border-radius: 5px;
		padding: 10px;
		color: #656565;
		font-family: 'Courier New', Courier, monospace;
		font-size: 16px;
		height: 80px;
		p {
			text-align: center;
		}
	}


	/* HTML: <div class="loader"></div> */
	.loader {
		height: 4px;
		width: 150px;
		--c: no-repeat linear-gradient(#37de8d 0 0);
		background: var(--c), var(--c), #e0e0e0;
		background-size: 60% 100%;
		animation: l16 3s infinite, pulse 1.5s infinite ease-in-out;
		margin-top: 10px;
		border-radius: 5px;
	}

	@keyframes l16 {
		0% {
			background-position: -150% 0, -150% 0;
		}
		66% {
			background-position: 250% 0, -150% 0;
		}
		100% {
			background-position: 250% 0, 250% 0;
		}
	}
}
